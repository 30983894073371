// material-ui
import { Box, CircularProgress, styled, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconFaceIdError } from '@tabler/icons-react';

import { animated, useSpring } from '@react-spring/web';
import PropTypes from 'prop-types';

const SlidingBox = styled(animated(Box))(({ theme }) => ({}));

// styles

const ErrorScreen = ({
    icon = null,
    err = null,
    translucent = false,
    fixed = false,
    title = null,
    subtitle = null,
    details = true,
    loading = false,
    errbg = null,
    loadingbg = null,
    errfg = null,
    loadingfg = null
}) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const fadeSlideProps = useSpring({
        delay: Math.floor(Math.random() * (500 - 250 + 1) + 250),
        to: { opacity: 1, translateY: '0', filter: 'saturate(1), blur(1rem)' },
        from: { opacity: 0, translateY: '1rem', filter: 'saturate(0), blur(0)' },
        // delay: 100,
        config: {
            // mass: 1,
            // tension: 5000,
            // friction: 200,
            // precision: 0.0001,
            // velocity: 0,
            duration: 250
        }
    });
    const bgHandler = () => {
        if (loading) {
            return loadingbg || theme.palette.primary.main;
        }
        if (err) {
            return errbg || theme.palette.grey[50];
        }
        return theme.palette.grey[50];
    };
    const fgHandler = () => {
        if (loading) {
            return loadingfg || theme.palette.primary.dark;
        }
        if (err) {
            return errfg || theme.palette.error.main;
        }
        return theme.palette.error.main;
    };
    return (
        <Box
            sx={{
                // zIndex: 999999999,
                transition: theme.transitions.create('all', {
                    easing: theme.transitions.easing.easeInOut,
                    duration: theme.transitions.duration.standard
                }),

                // background: translucent ? `linear-gradient(${theme.palette.grey[50]}, rgba(0, 0, 0,0.3))` : bgHandler(),
                // opacity: '0.6 !important',
                // backdropFilter: translucent ? 'blur(2rem)' : 'none',
                position: fixed ? 'fixed' : 'relative',
                top: 0,
                left: 0,
                width: '100%',
                height: '100vh',
                // height: `calc(100vh - 3.5rem)`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <SlidingBox
                style={fadeSlideProps}
                sx={{
                    display: 'flex',
                    flexDirection: matchUpMd ? 'row' : 'column',
                    alignItems: matchUpMd ? 'flex' : 'center',
                    justifyContent: 'flex-start',
                    maxWidth: matchUpMd ? '40%' : '90vw',
                    // background: theme.palette.primary.light,
                    // borderRadius: config.borderRadiusMd,
                    p: 2
                }}
            >
                {loading && (
                    <CircularProgress
                        thickness={5}
                        size={48}
                        sx={{
                            color: fgHandler()
                            // minWidth: '3rem',
                            // minHeight: '3rem'
                        }}
                    />
                )}
                {err && !loading && (
                    <Box sx={{ width: '4rem', height: '4rem' }}>
                        {icon ? icon : <IconFaceIdError color={theme.palette.error.main} stroke={2} size={72} />}
                    </Box>
                )}
                {err && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            mt: matchUpMd ? '.5rem' : '1rem',
                            ml: matchUpMd ? '1rem' : 0
                        }}
                    >
                        <Typography
                            variant="h5"
                            color={fgHandler()}
                            sx={{ mb: 2, fontWeight: 500, textAlign: matchUpMd ? 'left' : 'center' }}
                        >
                            {title || 'An error occurred'}
                        </Typography>

                        {/* <Typography variant="h6" color={fgHandler} sx={{ fontSize: '1rem', fontWeight: 400 }}>
                            {subtitle || 'Complete error:'}
                        </Typography> */}
                        <Typography variant="h6" color={fgHandler} sx={{ fontSize: '1rem', fontWeight: 400 }}>
                            {subtitle || 'Please try again later.'}
                        </Typography>
                        {/* {details && (
                            <Typography
                                variant="h6"
                                color={fgHandler}
                                sx={{
                                    mt: '.5rem',
                                    fontSize: '1rem',
                                    fontFamily: 'monospace',
                                    background: theme.palette.grey[50],
                                    borderRadius: config.borderRadiusSm,
                                    p: '.5rem 1rem'
                                }}
                            >
                                {err?.message}
                            </Typography>
                        )} */}
                    </Box>
                )}
            </SlidingBox>
        </Box>
    );
};

ErrorScreen.propTypes = {
    err: PropTypes.object,
    loading: PropTypes.bool,
    top: PropTypes?.string,
    left: PropTypes?.string,
    bottom: PropTypes?.string,
    right: PropTypes?.string,
    errbg: PropTypes?.string,
    loadingbg: PropTypes?.string,
    errfg: PropTypes?.string,
    loadingfg: PropTypes?.string,
    details: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    icon: PropTypes.element,
    translucent: PropTypes.bool,
    fixed: PropTypes.bool
};

export default ErrorScreen;
