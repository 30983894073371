/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */

export default function themePalette(theme) {
    const lightMode = theme?.customization?.mode === 'light';
    if (lightMode) {
        return {
            mode: theme?.customization?.mode,
            common: {
                black: theme.colors?.paper
            },
            bg: theme.colors?.paper,
            primary: {
                light: theme.colors?.darkPrimaryLight,
                main: theme.colors?.darkPrimaryMain,
                dark: theme.colors?.darkPrimaryDark,
                subtle: theme.colors?.darkPrimary200,
                200: theme.colors?.darkPrimary200,
                800: theme.colors?.darkPrimary800
            },
            secondary: {
                light: theme.colors?.darkSecondaryLight,
                main: theme.colors?.darkSecondaryMain,
                dark: theme.colors?.darkSecondaryDark,
                bg: theme.colors?.darkSecondaryBg,
                200: theme.colors?.darkSecondary200,
                800: theme.colors?.darkSecondary800
            },
            accent: {
                light: theme.colors?.darkAccentLight,
                main: theme.colors?.darkAccentMain,
                dark: theme.colors?.darkAccentDark
            },
            error: {
                lighter: theme.colors?.errorLighter,
                light: theme.colors?.errorLight,
                main: theme.colors?.errorMain,
                dark: theme.colors?.errorDark
            },
            orange: {
                light: theme.colors?.orangeLight,
                main: theme.colors?.orangeMain,
                dark: theme.colors?.orangeDark
            },
            warning: {
                light: theme.colors?.warningLight,
                main: theme.colors?.warningMain,
                dark: theme.colors?.warningDark
            },
            success: {
                light: theme.colors?.successLight,
                200: theme.colors?.success200,
                main: theme.colors?.successMain,
                dark: theme.colors?.successDark
            },
            grey: {
                lightest: theme.colors?.darkGrey5,
                10: theme.colors?.darkGrey10,
                rgba10: theme.colors?.darkGrey10rgba,
                50: theme.colors?.darkGrey50,
                rgba50: theme.colors?.darkGrey50rgba,
                75: theme.colors?.darkGrey75,
                100: theme.colors?.darkGrey100,
                rgba100: theme.colors?.darkGrey100rgba,
                200: theme.colors?.darkGrey200,
                300: theme.colors?.darkGrey300,
                500: theme.colors?.darkGrey500,
                600: theme.colors?.darkGrey600,
                700: theme.colors?.darkGrey700,
                900: theme.colors?.darkGrey900
            },
            dark: {
                light: theme.colors?.darkTextPrimary,
                main: theme.colors?.darkLevel1,
                dark: theme.colors?.darkLevel2,
                800: theme.colors?.darkBackground,
                900: theme.colors?.darkPaper
            },
            text: {
                primary: theme.colors?.darkTextPrimary,
                secondary: theme.colors?.darkTextSecondary,
                dark: theme.colors?.textDark,
                hint: theme.colors?.grey100,
                subtle: theme.colors?.subtleText,
                body: theme.colors?.bodyText
            },
            background: {
                paper: theme.colors?.paper,
                default: theme.colors?.darkBackgroundDefault,
                main: theme.colors?.paper
            }
        };
    }
    return {
        mode: theme?.customization?.mode,
        common: {
            black: theme.colors?.darkPaper
        },
        bg: theme.colors?.darkPaper,
        primary: {
            light: theme.colors?.primaryLight,
            main: theme.colors?.primaryMain,
            dark: theme.colors?.primaryDark,
            subtle: theme.colors?.primary200,
            200: theme.colors?.primary200,
            800: theme.colors?.primary800
        },
        secondary: {
            light: theme.colors?.secondaryLight,
            main: theme.colors?.secondaryMain,
            dark: theme.colors?.secondaryDark,
            bg: theme.colors?.secondaryBg,
            200: theme.colors?.secondary200,
            800: theme.colors?.secondary800
        },
        accent: {
            light: theme.colors?.accentLight,
            main: theme.colors?.accentMain,
            dark: theme.colors?.accentDark
        },
        error: {
            lighter: theme.colors?.errorLighter,
            light: theme.colors?.errorLight,
            main: theme.colors?.errorMain,
            dark: theme.colors?.errorDark
        },
        orange: {
            light: theme.colors?.orangeLight,
            main: theme.colors?.orangeMain,
            dark: theme.colors?.orangeDark
        },
        warning: {
            light: theme.colors?.warningLight,
            main: theme.colors?.warningMain,
            dark: theme.colors?.warningDark
        },
        success: {
            light: theme.colors?.successLight,
            200: theme.colors?.success200,
            main: theme.colors?.successMain,
            dark: theme.colors?.successDark
        },
        grey: {
            lightest: theme.colors?.grey5,
            10: theme.colors?.grey10,
            rgba10: theme.colors?.grey10rgba,
            50: theme.colors?.grey50,
            rgba50: theme.colors?.grey50rgba,
            75: theme.colors?.grey75,
            100: theme.colors?.grey100,
            rgba100: theme.colors?.grey100rgba,
            200: theme.colors?.grey200,
            300: theme.colors?.grey300,
            500: theme.darkTextSecondary,
            600: theme.heading,
            700: theme.darkTextPrimary,
            900: theme.textDark
        },
        dark: {
            light: theme.colors?.darkTextPrimary,
            main: theme.colors?.darkLevel1,
            dark: theme.colors?.darkLevel2,
            800: theme.colors?.darkBackground,
            900: theme.colors?.darkPaper
        },
        text: {
            primary: theme.darkTextPrimary,
            secondary: theme.darkTextSecondary,
            dark: theme.textDark,
            hint: theme.colors?.grey100,
            subtle: theme.colors?.subtleText,
            body: theme.colors?.bodyText
        },
        background: {
            paper: theme.paper,
            default: theme.backgroundDefault,
            main: theme.paper
        }
    };
}
