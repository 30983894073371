import { lazy } from 'react';

// project imports
import AuthLayout from 'layout/AuthLayout';
import ContainerLayout from 'layout/ContainerLayout';
import MainLayout from 'layout/MainLayout';
import PublicLayout from 'layout/PublicLayout';
import Loadable from 'ui-component/Loadable';

// ! 404
const NotFound = Loadable(lazy(() => import('views/not-found')));
// * Auth
const Login = Loadable(lazy(() => import('views/auth/authentication3/Login3')));

// * ADMIN
const AdminDashboard = Loadable(lazy(() => import('views/admin/dashboard')));
const Profile = Loadable(lazy(() => import('views/admin/me')));
const Artists = Loadable(lazy(() => import('views/admin/artists')));
const ArtistEdit = Loadable(lazy(() => import('views/admin/artists/edit')));
const BookingsEdit = Loadable(lazy(() => import('views/admin/artists/bookingsedit')));
const News = Loadable(lazy(() => import('views/admin/news')));
const NewsEdit = Loadable(lazy(() => import('views/admin/news/edit')));
const AboutEdit = Loadable(lazy(() => import('views/admin/about')));
const TeamEdit = Loadable(lazy(() => import('views/admin/team')));
const TeamStoriesEdit = Loadable(lazy(() => import('views/admin/team/edit')));
const Shows = Loadable(lazy(() => import('views/admin/shows')));
const ShowEdit = Loadable(lazy(() => import('views/admin/shows/edit')));
// * PUBLIC
const Home = Loadable(lazy(() => import('views/public/dashboard')));
//* Artists */
const PublicArtists = Loadable(lazy(() => import('views/public/artists')));
const ArtistPage = Loadable(lazy(() => import('views/public/artists/artist')));
//* Artists */
const PublicShows = Loadable(lazy(() => import('views/public/shows')));
const ShowPage = Loadable(lazy(() => import('views/public/shows/show')));
//* News */
const PublicNews = Loadable(lazy(() => import('views/public/news')));
const ArticlePage = Loadable(lazy(() => import('views/public/news/article')));
//* About */
const AboutPage = Loadable(lazy(() => import('views/public/about')));
//* Awareness team */
const TeamPage = Loadable(lazy(() => import('views/public/team')));
const TeamStoryPage = Loadable(lazy(() => import('views/public/team/article')));
//* Privacy policy */
const PrivacyPolicy = Loadable(lazy(() => import('views/public/privacy')));
// ==============================|| ROUTING ||============================== //

const FrontendRoutes = {
    path: '/',
    element: <ContainerLayout />,
    children: [
        // ? PUBLIC
        {
            path: '',
            element: <PublicLayout />,
            children: [
                {
                    path: '',
                    element: <Home />
                },
                {
                    path: 'artists',
                    element: <PublicArtists />
                },
                {
                    path: 'artists/:id',
                    element: <ArtistPage />
                },
                {
                    path: 'shows',
                    element: <PublicShows />
                },
                {
                    path: 'shows/:id',
                    element: <ShowPage />
                },
                {
                    path: 'news',
                    element: <PublicNews />
                },
                {
                    path: 'news/:id',
                    element: <ArticlePage />
                },
                {
                    path: 'about',
                    element: <AboutPage />
                },
                {
                    path: 'awareness-team',
                    element: <TeamPage />
                },
                {
                    path: 'awareness-team/:id',
                    element: <TeamStoryPage />
                },
                {
                    path: 'privacy',
                    element: <PrivacyPolicy />
                }
            ]
        },
        // ? ADMIN
        {
            path: 'admin/',
            element: <MainLayout />,
            children: [
                {
                    path: '',
                    element: <AdminDashboard />
                },
                //     {
                //         path: 'articoli/:articleId',
                //         element: <ArticoloEdit />
                //     },
                {
                    path: 'dashboard',
                    element: <AdminDashboard />
                },
                {
                    path: 'me',
                    element: <Profile />
                },
                {
                    path: 'artists',
                    element: <Artists />
                },
                {
                    path: 'artists/:id',
                    element: <ArtistEdit />
                },
                {
                    path: 'artists/:id/bookings/:bookingId',
                    element: <BookingsEdit />
                },
                {
                    path: 'news',
                    element: <News />
                },
                {
                    path: 'news/:id',
                    element: <NewsEdit />
                },
                {
                    path: 'about',
                    element: <AboutEdit />
                },
                {
                    path: 'team',
                    element: <TeamEdit />
                },
                {
                    path: 'team/:id',
                    element: <TeamStoriesEdit />
                },
                {
                    path: 'shows',
                    element: <Shows />
                },
                {
                    path: 'shows/:showId',
                    element: <ShowEdit />
                }
            ]
        },
        // ? AUTH
        {
            path: 'admin/',
            element: <AuthLayout />,
            children: [
                {
                    path: 'login',
                    element: <Login />
                    // element: <ArticleList />
                }
            ]
        },
        // // * NOT FOUND
        {
            path: 'admin/*',
            element: <MainLayout />,
            children: [
                {
                    path: '*',
                    element: <NotFound />
                }
            ]
        },
        {
            path: '*',
            element: <PublicLayout />,
            children: [
                {
                    path: '*',
                    element: <NotFound />
                }
            ]
        }
    ]
};

const useRoutes = () => FrontendRoutes;

export { FrontendRoutes, useRoutes };
