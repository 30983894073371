import { useSelector } from 'react-redux';

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

// routing
import { PublicRoutes } from './routes';
// import loggr from 'utils/consolr';

// defaultTheme
import themes from 'themes';
// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import reportWebVitals from 'reportWebVitals';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    // const [cookies, setCookie] = useCookies();
    useEffect(() => {
        const handleContextmenu = (e) => {
            e.preventDefault();
        };
        document.addEventListener('contextmenu', handleContextmenu);
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu);
        };
    }, []);
    // console.log(customization);
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <HelmetProvider>
                    <CssBaseline />
                    <NavigationScroll>
                        <PublicRoutes />
                    </NavigationScroll>
                </HelmetProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

reportWebVitals(console.log);

export default App;
